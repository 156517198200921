
import emailJs from 'emailjs-com';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

const serviceID = 'service_q0auihh';
const templateID = 'template_pokka7i';
const userID = 'user_fYNVhS3pqFbgji8kk0dkb';

export default defineComponent({
	name: 'Contact',
	data() {
		return {
			errors: {
				name: false,
				email: false,
				phone: false,
				company: false,
				message: false,
			},
			name: '',
			email: '',
			phone: '',
			company: '',
			message: '',
			namePlaceholder: this.t('contact.name_placeholder'),
			emailPlaceholder: this.t('contact.email_placeholder'),
			phonePlaceholder: this.t('contact.phone_placeholder'),
			companyPlaceholder: this.t('contact.company_placeholder'),
			messagePlaceholder: this.t('contact.message_placeholder'),
			submitButton: this.t('contact.submit_button'),
		};
	},
	methods: {
		sendEmail: async function (event: Event): Promise<void> {
			this.errors.name = false;
			this.errors.email = false;
			this.errors.phone = false;
			this.errors.company = false;
			this.errors.message = false;

			if (!this.name) {
				this.errors.name = true;
			}
			if (!this.email) {
				this.errors.email = true;
			}
			if (!this.phone) {
				this.errors.phone = true;
			}
			if (!this.company) {
				this.errors.company = true;
			}
			if (!this.message) {
				this.errors.message = true;
			}

			if (Object.values(this.errors).indexOf(true) > -1) {
				return;
			}

			try {
				let params = {
					from_name: this.name,
					name: this.name,
					email: this.email,
					phone: this.phone,
					company: this.company,
					message: this.message,
				};

				let sendResult = await emailJs.send(serviceID, templateID, params, userID);

				console.log('sendResult', sendResult);
			} catch (error) {
				console.log(error);
			}

			// reset form
			this.name = '';
			this.email = '';
			this.phone = '';
			this.company = '';
			this.message = '';

			event.preventDefault();
		},
	},
	setup() {
		const { t } = useI18n({
			userScope: 'global',
			inheritLocale: true,
		});
		return { t };
	},
});
